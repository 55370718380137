<template>
  <div>
    <a-modal
      :zIndex="2000"
      title="Add Student Class Confirmation"
      :visible="visibleAddStudentConfirmation"
      :confirm-loading="confirmLoadingAddStudentConfirmation"
      :destroyOnClose="true"
      okText="Add Now"
      okType="primary"
      @ok="handleOkAddStudentConfirmation"
      @cancel="handleToggleAddStudentConfirmation"
    >
      <h3>
        Are you sure want to add {{ selectedRowStudent.length }} student(s) to
        {{ dataStudent.tingkat }}-{{ dataStudent.simbol }} Class?
      </h3>
      <p class="text-danger mt-2 mb-4">*This change will be affected to system</p>
      <a-table
        :columns="selectedColumns"
        :data-source="selectedRowStudent"
        :scroll="{ y: 150 }"
        :pagination="{ pageSize: 40, hideOnSinglePage: true }"
        bordered
      >
        <div class="d-flex justify-content-end" slot="footer" slot-scope="data">
          Total Student: {{ data.length }}
        </div>
        <div slot="murid" slot-scope="text, record">
          <div><b>{{record.nama}}</b></div>
          <div>{{record.nis || '-'}} / {{record.jenis_kelamin || '-'}}</div>
        </div>
      </a-table>
      <hr />
      <a-checkbox :checked="isNotify" @change="onChangeIsNotify">
        Send notification via e-mail to selected student(s)
      </a-checkbox>
    </a-modal>
    <div class="d-flex">
      <div class="mr-3">
        <!-- <a-input-search size="large" placeholder="input search text" enter-button v-model="search" @search="searchStudent" /> -->
        <a-input
          v-model="search"
          @keyup="searchStudent"
          size="large"
          placeholder="Search Student..."
        />
      </div>
      <div class="mr-3">
        <a-tooltip>
          <template slot="title">Required to Level</template>
          <a-select
            @change="handleSelectNextLevel"
            size="large"
            default-value="all"
            style="width: 150px"
          >
            <a-select-option key="all">All</a-select-option>
            <a-select-option v-for="level in levelData" :key="level.id">{{
              level.nama
            }}</a-select-option>
          </a-select>
        </a-tooltip>
      </div>
      <!-- <div class="mr-3">
        <a-tooltip>
          <a-select
            v-model="selectStatusStudent"
            @change="changeStatusStudent"
            size="large"
            default-value="all"
            style="min-width: 200px"
          >
            <a-select-option key="available">Only Available Student</a-select-option>
            <a-select-option key="all">All Student</a-select-option>
          </a-select>
        </a-tooltip>
      </div> -->
      <div class="ml-auto">
        <a-button
          @click.prevent="addStudentSelected"
          :disabled="!selectedRowStudent.length"
          class="ml-3"
          type="primary"
          size="large"
          :loading="loadingAddStudent"
        >
          <a-icon v-if="!loadingAddStudent" type="plus" />
          {{
            loadingAddStudent
              ? "Adding..."
              : `Add ${
                  selectedRowStudent.length ? selectedRowStudent.length : ""
                } Selected Student(s)`
          }}
        </a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="studentColumns"
        :data-source="studentDataTable"
        :pagination="paginationStudent"
        :loading="loadingTableStudent"
        :row-selection="rowSelection"
        @change="handleTableChange"
        bordered
      >
        <div slot="nis" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
        <div slot="nama" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
        <div slot="jenis_kelamin" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
        <div slot="nextLevel" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
        <div slot="previousClass" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
        <div slot="currentClass" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const studentColumns = [
  {
    title: 'NIS',
    dataIndex: 'nis',
    key: 'nis',
    // width: 250,
    scopedSlots: { customRender: 'nis' },
  },
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    sorter: true,
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Gender',
    dataIndex: 'jenis_kelamin',
    key: 'jenis_kelamin',
    // width: 200,
    sorter: true,
    scopedSlots: { customRender: 'jenis_kelamin' },
  },
  {
    title: 'Required to Level',
    dataIndex: 'nextLevel',
    key: 'nextLevel',
    // width: 200,
    scopedSlots: { customRender: 'nextLevel' },
    align: 'center',
  },
  {
    title: 'Status Class',
    key: 'status',
    // width: 200,
    children: [
      {
        title: 'Previous Class',
        dataIndex: 'previousClass',
        key: 'previousClass',
        scopedSlots: { customRender: 'previousClass' },
        align: 'center',
      },
      {
        title: 'Current Class',
        dataIndex: 'currentClass',
        key: 'currentClass',
        scopedSlots: { customRender: 'currentClass' },
        align: 'center',
      },
    ],
  },
]

const selectedColumns = [
  {
    title: 'List of Selected Student',
    dataIndex: 'murid',
    key: 'murid',
    scopedSlots: { customRender: 'murid' },
    width: 220,
  },
]

export default {
  name: 'AddStudent',
  props: {
    dataStudent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      studentColumns,
      loadingAddStudent: false,
      loadingTableStudent: false,
      visibleAddStudentConfirmation: false,
      confirmLoadingAddStudentConfirmation: false,
      isNotify: true,
      paginationStudent: {},
      studentDataTable: [],
      selectedRowStudent: [],
      levelData: [],
      search: '',
      setSelectedNextLevel: null,
      selectedColumns,
      selectStatusStudent: 'available',
    }
  },
  methods: {
    onChangeIsNotify(e) {
      this.isNotify = e.target.checked
    },
    handleToggleAddStudentConfirmation() {
      this.visibleAddStudentConfirmation = !this.visibleAddStudentConfirmation
    },
    handleOkAddStudentConfirmation() {
      this.loadingAddStudent = true
      this.confirmLoadingAddStudentConfirmation = true
      const id = this.dataStudent.id
      this.$store.dispatch('studentClass/POST_STUDENT_CLASS_SELECTED', { id, dataStudents: this.selectedRowStudent, isNotify: this.isNotify })
        .then(isSuccess => {
          this.loadingAddStudent = false
          this.confirmLoadingAddStudentConfirmation = false
          this.handleToggleAddStudentConfirmation()
          this.closeModalAddStudent()
          if (isSuccess) {
            this.$notification.success({
              message: 'Success',
              description: `${this.selectedRowStudent.length} student(s) data has been added`,
            })
            this.selectedRowStudent = []
          } else {
            this.$notification.error({
              message: 'Failed',
              description: 'student(s) data has not been added',
            })
            this.selectedRowStudent = []
          }
        })
    },
    closeModalAddStudent() {
      console.log('emit close')
      this.$emit('close-modal')
    },
    addStudentSelected() {
      this.handleToggleAddStudentConfirmation()
    },
    searchStudent() {
      this.fetchDataStudent({
        search: this.search,
        idNextLevel: this.setSelectedNextLevel,
      })
    },
    changeStatusStudent() {
      this.fetchDataStudent({
        search: this.search,
        idNextLevel: this.setSelectedNextLevel,
      })
    },
    handleSelectNextLevel(value) {
      if (value === 'all') {
        value = null
      }
      this.setSelectedNextLevel = value
      this.fetchDataStudent({
        search: this.search,
        idNextLevel: this.setSelectedNextLevel,
      })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.paginationStudent }
      let order = 'ASC'
      let sortBy = ''
      pager.current = pagination.current
      this.paginationStudent = pager
      if (sorter) {
        sortBy = sorter.field
        // console.log(sorter)
        if (sorter.order === 'ascend') {
          order = 'ASC'
        } else if (sorter.order === 'descend') {
          order = 'DESC'
        }
      }
      this.fetchDataStudent({
        search: this.search,
        idNextLevel: this.setSelectedNextLevel,
        page: pagination.current,
        order,
        sortBy,
      })
    },
    fetchDataLevel(params = { page: 'all', order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.programLoadingTable = true
      this.$store.dispatch('master/FETCH_LEVEL', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.levelData = res.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
    fetchDataStudent(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '', idNextLevel: null }) {
      const id = this.dataStudent.id_tahun_ajaran
      this.loadingTableStudent = true
      this.$store.dispatch('studentClass/FETCH_ALL_STUDENT', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy, id, idNextLevel: params.idNextLevel })
        .then(res => {
          this.loadingTableStudent = false
          const pagination = { ...this.paginationStudent }
          pagination.total = res.total
          this.paginationStudent = pagination
          this.studentDataTable = res.students.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataLevel()
    this.fetchDataStudent()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          const newData = selectedRows.filter(row => selectedRowKeys.find(key => key === row.id))
          const oldData = []
          for (let i = 0; i < this.selectedRowStudent.length; i++) {
            const student = this.selectedRowStudent[i]
            const findStudent = newData.find(dat => dat.id === student.id)
            if (!findStudent) {
              const isOnPage = this.studentDataTable.find(dat => dat.id === student.id)
              if (!isOnPage) oldData.push(student)
            }
          }
          this.selectedRowStudent = oldData.concat(newData)
        },
        selectedRowKeys: this.rowSessionSelectedKey,
        getCheckboxProps: record => {
          return {
            props: {
              disabled: Boolean(record.currentClass), // Column configuration not to be checked
            },
          }
        },
      }
    },
    rowSessionSelectedKey() {
      return this.selectedRowStudent.map(row => row.key)
    },
  },
}
</script>

<style>
</style>
